.App {
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Pins {
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.responsiveImg {
  max-width: 100%;
}

.pin-img {
  max-width: 300px;
}

.display-line-break {
  white-space: pre-line;
}

.carouselProvider {
  height: 500px;
  /*max-height: 500px;*/
  /*overflow-y: none;*/
}

.Slider {
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.Slider-tray {
  height: 500px;
  max-height: 500px;
}

.Slider-content {
  height: 500px;
  max-height: 500px;
  /*overflow-y: auto;*/
}

.Slider-inner-content {
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.Slider-grid {
  /*height: 500px;*/
  /*max-height: 500px;*/
  /*overflow-y: auto;*/
}

blockquote {
  background: #525252;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote p {
  display: inline;
}

.ResumeContainer {
  margin:auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}